<template>
  <div id="notif-page">
    <NotificationList v-if="isLogin" />
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import NotificationList from '@/components/Notification/NotificationList'

  export default {
    name: 'Notification',
    components: { NotificationList },
    computed: {
      ...mapState([
        'isLogin'
      ])
    },
    data () {
      return {
        data: null
      }
    },
    methods: {
    },
    mounted () {
      if (!this.isLogin) {
        this.$router.push({ name: 'Login'})
      }

      this.setMetaInfo(null, this.$route.name)

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setActivePage', 'Notification')
    }
  }
</script>

<style scoped>
.notification {
  padding: 12px;
}
.n-sub {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}
.n-sub2 {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}
.n-sub3 {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #666261;
}
</style>
