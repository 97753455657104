<template>
  <div id="notif-list">
    <v-layout wrap :class="isMobile ? 'notification mt-16' : 'notification'" v-if="data">
      <v-flex xs12>
        <v-layout wrap class="notif-list py-2" v-ripple>
          <v-flex xs12 @click="toPage('Order')">
            <h1 class="n-sub">Transactions</h1>
            <p class="n-sub2 mb-0">Check the status and information regarding products</p>
          </v-flex>

          <div class="notif-action">
            <div class="notif-badge" v-if="data && data.total_order">
              <span class="white--text notif-badge-text">
                {{ data.total_order.waiting_payment + data.total_order.paid + data.total_order.stock_distribution + data.total_order.delivery }}
              </span>
            </div>
            <v-icon small>mdi-chevron-right</v-icon>
          </div>
        </v-layout>
        <v-divider class="mt-2 mb-2"></v-divider>

        <v-layout wrap class="notif-list py-2" v-ripple>
          <v-flex xs12 @click="toPage('MyReview')">
            <h1 class="n-sub">Reviews</h1>
            <p class="n-sub2 mb-0">Reviews and rate our products </p>
          </v-flex>

          <div class="notif-action">
            <div class="notif-badge" v-if="data && data.total_rating">
              <span class="white--text notif-badge-text">
                {{ data.total_rating }}
              </span>
            </div>
            <v-icon small>mdi-chevron-right</v-icon>
          </div>
        </v-layout>
        <v-divider class="mt-2 mb-2"></v-divider>

        <v-layout wrap class="notif-list py-2" v-ripple>
          <v-flex xs12 @click="toPage('MyDiscussion')">
            <h1 class="n-sub">Discusssions</h1>
            <p class="n-sub2 mb-0">Ask about our products</p>
          </v-flex>

          <div class="notif-action">
            <div class="notif-badge" v-if=" data && data.total_discussion">
              <span class="white--text notif-badge-text">
                {{ data.total_discussion }}
              </span>
            </div>
            <v-icon small>mdi-chevron-right</v-icon>
          </div>
        </v-layout>
        <v-divider v-if="isMobile" class="mt-2 mb-2"></v-divider>
      </v-flex>
    </v-layout>

    <v-layout wrap :class="isMobile ? 'notification mt-16' : 'notification'" v-else>
      <span class="n-sub2">No Data</span>
    </v-layout>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'

  export default {
    name: 'Notification',
    computed: {
      ...mapState([
        'isMobile',
        'isLogin',
        'token'
      ])
    },
    data () {
      return {
        data: null
      }
    },
    methods: {
      toPage (link) {
        this.$router.push({name: link})
      },
      getNotif () {
        // this.$store.commit('setIsLoading', true)
      
        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token,
        }

        axios.get('/api/v1/pages/notification')
          .then(response => {
            if (response.data.status) {
              this.data = response.data.data
              this.$store.commit('setNotifNumber', this.data.total)
              this.$store.commit('setReviewNumber', this.data.total_rating)
            } else {
              this.data = {}
            }
          })
          .catch(error => {
            this.errorHandling(error)
          })
          // .finally(() => {
          //   this.$store.commit('setIsLoading', false)
          // })
        }
    },
    mounted () {
      if (this.isLogin) {
        this.getNotif()
      }
    }
  }
</script>

<style scoped>
.notification {
  padding: 12px;
}
.notif-list {
  position: relative;
  cursor: pointer;
}
.notif-badge {
  background-color: red;
  text-align: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 8px;
  position: relative;
}
.notif-badge-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.notif-action {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.n-sub {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #000000;
}
.n-sub2 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #898989;
}
.n-sub3 {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #666261;
}
</style>
